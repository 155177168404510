import React, { useState } from 'react';
import { Server, Shield, Code, Settings, BarChart, Network, Database, Users, ArrowRight, CheckCircle, Monitor } from 'lucide-react';
import Layout from '../components/Layout';
import { useNavigate } from 'react-router-dom';

const PageBanner = () => (
  <div className="relative bg-gradient-to-r from-gray-900 to-gray-800 overflow-hidden">
    <div className="absolute inset-0">
      {/* Pattern de fond */}
      <div className="absolute inset-0 bg-[linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341),linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341)] bg-[length:60px_60px] bg-[position:0_0,30px_30px] opacity-20"></div>
      {/* Overlay animé */}
      <div className="absolute inset-0 bg-gradient-to-r from-orange-500/20 via-orange-500/10 to-orange-500/20 animate-gradient-x"></div>
      <div className="absolute -left-1/4 -top-1/4 w-1/2 h-1/2 bg-orange-500/20 rounded-full blur-3xl"></div>
      <div className="absolute -right-1/4 -bottom-1/4 w-1/2 h-1/2 bg-orange-500/20 rounded-full blur-3xl"></div>
    </div>

    <div className="relative max-w-7xl mx-auto px-4 py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white mb-6">
          Études et Conseils <br />en Informatique
        </h1>
        <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
          Expertise et accompagnement pour vos projets informatiques
        </p>
        <div className="flex justify-center space-x-4">
          <button className="px-8 py-3 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition-all shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 flex items-center space-x-2">
            <span>Nos experts vous conseillent</span>
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
);

const ConsultingService = ({ title, description, features, icon: Icon }) => (
  <div className="bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow border border-orange-100 p-8">
    <div className="flex items-start space-x-4">
      <div className="flex-shrink-0">
        <div className="w-12 h-12 rounded-lg bg-orange-50 flex items-center justify-center">
          <Icon className="w-6 h-6 text-orange-500" />
        </div>
      </div>
      <div>
        <h3 className="text-xl font-bold text-gray-900 mb-3">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
        <ul className="space-y-2">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start space-x-2">
              <CheckCircle className="w-5 h-5 text-orange-500 flex-shrink-0 mt-0.5" />
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

const EtudesConseilInfo = () => {
  const navigate = useNavigate();
  const consultingServices = [
    {
      icon: Settings,
      title: "Conseil en Architecture Informatique",
      description: "Conception et optimisation de votre infrastructure IT",
      features: [
        "Analyse de l'existant et recommandations",
        "Conception d'architectures évolutives",
        "Optimisation des performances",
        "Plans de migration et d'évolution"
      ]
    },
    {
      icon: Shield,
      title: "Audit et Sécurité",
      description: "Évaluation et renforcement de la sécurité de vos systèmes",
      features: [
        "Audit de sécurité complet",
        "Tests de pénétration",
        "Plan de sécurisation",
        "Mise en conformité RGPD"
      ]
    },
    {
      icon: Database,
      title: "Gestion des Données",
      description: "Optimisation de vos systèmes de gestion de données",
      features: [
        "Architecture des bases de données",
        "Plan de sauvegarde et restauration",
        "Optimisation des performances",
        "Migration des données"
      ]
    },
    {
      icon: Network,
      title: "Infrastructure Réseau",
      description: "Conception et optimisation de votre réseau d'entreprise",
      features: [
        "Audit de l'infrastructure existante",
        "Conception de solutions réseau",
        "Sécurisation des accès",
        "Plan de maintenance"
      ]
    }
  ];

  const expertiseAreas = [
    {
      icon: Monitor,
      title: "Systèmes d'Information",
      items: [
        "Architecture SI",
        "Intégration de solutions",
        "Transformation digitale",
        "Optimisation des processus"
      ]
    },
    {
      icon: Server,
      title: "Infrastructure",
      items: [
        "Cloud Computing",
        "Virtualisation",
        "Stockage",
        "Sauvegarde"
      ]
    },
    {
      icon: Code,
      title: "Développement",
      items: [
        "Applications sur mesure",
        "Intégration API",
        "Microservices",
        "DevOps"
      ]
    },
    {
      icon: BarChart,
      title: "Performance & Analyse",
      items: [
        "Monitoring",
        "Optimisation",
        "Reporting",
        "Analyse prédictive"
      ]
    }
  ];

  return (
    <Layout>
      <PageBanner />
      
      {/* Notre Approche */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Notre Approche</h2>
            <p className="text-gray-600">
              Une méthodologie éprouvée pour répondre à vos besoins en informatique
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                step: "01",
                title: "Analyse",
                description: "Étude approfondie de vos besoins et de votre environnement informatique"
              },
              {
                step: "02",
                title: "Diagnostic",
                description: "Identification des points d'amélioration et des solutions potentielles"
              },
              {
                step: "03",
                title: "Recommandations",
                description: "Proposition de solutions adaptées et plan d'action détaillé"
              },
              {
                step: "04",
                title: "Accompagnement",
                description: "Suivi de la mise en œuvre et support continu"
              }
            ].map((phase, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg border border-orange-100">
                <div className="text-4xl font-bold text-orange-500 mb-4">{phase.step}</div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">{phase.title}</h3>
                <p className="text-gray-600">{phase.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Services de Conseil */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Services de Conseil</h2>
            <p className="text-gray-600">
              Des solutions expertes pour accompagner votre transformation numérique
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {consultingServices.map((service, index) => (
              <ConsultingService key={index} {...service} />
            ))}
          </div>
        </div>
      </section>

      {/* Domaines d'Expertise */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Nos Domaines d'Expertise</h2>
            <p className="text-gray-600">
              Une expertise technique pointue au service de vos projets
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {expertiseAreas.map((area, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg border border-orange-100 p-6">
                <div className="w-12 h-12 rounded-lg bg-orange-50 flex items-center justify-center mb-4">
                  <area.icon className="w-6 h-6 text-orange-500" />
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-4">{area.title}</h3>
                <ul className="space-y-2">
                  {area.items.map((item, idx) => (
                    <li key={idx} className="flex items-center space-x-2">
                      <CheckCircle className="w-4 h-4 text-orange-500" />
                      <span className="text-gray-600">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA */}
      <section className="py-20 bg-gradient-to-r from-gray-900 to-gray-800 relative overflow-hidden">
        <div className="absolute inset-0 bg-orange-500/10"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Besoin d'un conseil d'expert ?
          </h2>
          <p className="text-lg text-gray-300 mb-8 max-w-2xl mx-auto">
            Nos consultants sont à votre disposition pour étudier vos besoins et vous proposer les meilleures solutions.
          </p>
          <button className="px-8 py-3 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition-all shadow-lg hover:shadow-xl flex items-center space-x-2 mx-auto" onClick={()=>navigate('/rejoignez-nous')}>
            <span>Prendre rendez-vous</span>
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </section>
    </Layout>
  );
};

export default EtudesConseilInfo;