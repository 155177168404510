{/* Quality Process Section */}
const QualityProcessSection = () => {
    const processes = [
      {
        icon: (
          <svg className="w-16 h-16 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 7V5a2 2 0 012-2h2M7 3h2m2 0h2" />
          </svg>
        ),
        title: "Design Thinking",
        description: "Concevoir et créer des parcours digitaux adaptés à chaque besoin."
      },
      {
        icon: (
          <svg className="w-16 h-16 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
        ),
        title: "Développement Thinking",
        description: "Construire un socle technique robuste, sécurisé et évolutif."
      },
      {
        icon: (
          <svg className="w-16 h-16 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
          </svg>
        ),
        title: "Déploiement Thinking",
        description: "L'accès à vos besoins 24H/24 et 7J/J constitue notre priorité absolue."
      }
    ];
  
    return (
      <>
        <section className="py-20 bg-white overflow-hidden">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Header */}
            <div className="text-center mb-16">
              <p className="text-sm font-semibold text-gray-500 uppercase tracking-wide">
                WORKFLOW
              </p>
              <h2 className="mt-2 text-3xl md:text-4xl font-bold text-gray-900">
                Notre process qualité
              </h2>
              <div className="mt-4 max-w-3xl mx-auto">
                <p className="text-xl text-gray-500">
                  Nous concevons des interfaces et expérience utilisateur intuitive.
                </p>
              </div>
            </div>
  
            {/* Process Steps */}
            <div className="relative">
              {/* Connecting Lines */}
              <div className="hidden md:block absolute top-1/2 left-0 right-0 h-0.5 bg-gray-200 -mt-px transform -translate-y-1/2">
                <div className="absolute top-0 left-0 right-0 h-full">
                  <div className="relative h-full">
                    <div className="absolute h-full w-1/3 bg-orange-500"></div>
                  </div>
                </div>
              </div>
  
              <div className="grid grid-cols-1 md:grid-cols-3 gap-12 relative">
                {processes.map((process, index) => (
                  <div
                    key={index}
                    className="relative flex flex-col items-center text-center group"
                  >
                    {/* Icon Container */}
                    <div className="relative">
                      <div className="w-24 h-24 rounded-full bg-white border-2 border-orange-500 flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300 relative z-10">
                        {process.icon}
                      </div>
                      <div className="absolute inset-0 bg-orange-100 rounded-full transform scale-110 -z-10 opacity-0 group-hover:opacity-100 transition-opacity"></div>
                    </div>
  
                    {/* Content */}
                    <h3 className="text-xl font-bold text-gray-900 mb-4">
                      {process.title}
                    </h3>
                    <p className="text-gray-500 max-w-xs">
                      {process.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
  
        {/* NigerEmploi Banner */}
        <section className="bg-gradient-to-r from-orange-500 to-orange-600 py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <h2 className="text-2xl md:text-2xl font-bold text-white text-center md:text-left mb-6 md:mb-0">
                NigerEmploi.com est le 1er site d'emploi et de recrutement en ligne au Niger
              </h2>
              <a
                href="https://nigeremploi.com"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-8 py-3 border-2 border-white rounded-lg text-white font-semibold hover:bg-white hover:text-orange-600 transition-colors duration-300 group"
              >
                VOIR LES OFFRES
                <svg 
                  className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  />
                </svg>
              </a>
            </div>
          </div>
        </section>
      </>
    );
  };
  
  export default QualityProcessSection;