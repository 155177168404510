import { useState } from 'react';

const ContactSection = () => {
    const [formStatus, setFormStatus] = useState({
      isSubmitting: false,
      isSuccess: false,
      error: null
    });
  
    const contactInfo = [
      {
        icon: (
          <svg className="w-6 h-6 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
          </svg>
        ),
        title: "Adresse",
        content: "Quartier ZAC, Niamey. BP: 157 Niamey",
      },
      {
        icon: (
          <svg className="w-6 h-6 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
          </svg>
        ),
        title: "Téléphone",
        content: "+227 20 32 01 52 / +227 93 32 01 52",
        href: "tel:+22720320152"
      },
      {
        icon: (
          <svg className="w-6 h-6 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
        ),
        title: "E-Mail",
        content: "info@aydees.com",
        href: "mailto:info@aydees.com"
      }
    ];
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setFormStatus({ ...formStatus, isSubmitting: true });
      // Simuler un envoi
      setTimeout(() => {
        setFormStatus({
          isSubmitting: false,
          isSuccess: true,
          error: null
        });
        setTimeout(() => setFormStatus({
          isSubmitting: false,
          isSuccess: false,
          error: null
        }), 3000);
      }, 1000);
    };
  
    return (
      <section className="py-16 md:py-20 bg-gradient-to-br from-white to-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
            {/* Left Column */}
            <div className="bg-white rounded-2xl shadow-lg p-6 md:p-8 lg:p-10">
              <div className="mb-8">
                <span className="inline-block text-sm font-bold text-orange-500 uppercase tracking-wider mb-4">
                  POUR NOUS CONTACTER
                </span>
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-gray-900 leading-tight mb-4">
                  Nous serons heureux de vous lire et nous répondrons très rapidement
                </h2>
                <div className="h-1 w-20 bg-gradient-to-r from-orange-500 to-orange-600 rounded-full"></div>
              </div>
  
              {/* Contact Info Cards */}
              <div className="space-y-4">
                {contactInfo.map((info, index) => (
                  <a
                    key={index}
                    href={info.href}
                    className={`block p-5 bg-gray-50 rounded-xl transition-all duration-300 hover:shadow-md ${
                      info.href ? 'hover:-translate-y-0.5 cursor-pointer' : 'cursor-default'
                    }`}
                  >
                    <div className="flex items-center">
                      <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center bg-white rounded-lg shadow-sm">
                        {info.icon}
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-bold text-gray-900">
                          {info.title}
                        </h3>
                        <p className="text-gray-600 hover:text-orange-500 transition-colors font-medium">
                          {info.content}
                        </p>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
  
            {/* Right Column - Form */}
            <div className="bg-white rounded-2xl shadow-lg p-6 md:p-8 lg:p-10">
              <form onSubmit={handleSubmit} className="space-y-5">
                <div>
                  <label htmlFor="name" className="block text-sm font-bold text-gray-700 mb-2">
                    Prénom & Nom
                  </label>
                  <input
                    required
                    type="text"
                    id="name"
                    className="w-full px-4 py-3 rounded-lg bg-gray-50 border-2 border-gray-200 focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-all duration-300"
                    placeholder="Entrez votre nom complet"
                  />
                </div>
  
                <div>
                  <label htmlFor="email" className="block text-sm font-bold text-gray-700 mb-2">
                    E-Mail
                  </label>
                  <input
                    required
                    type="email"
                    id="email"
                    className="w-full px-4 py-3 rounded-lg bg-gray-50 border-2 border-gray-200 focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-all duration-300"
                    placeholder="Entrez votre adresse email"
                  />
                </div>
  
                <div>
                  <label htmlFor="subject" className="block text-sm font-bold text-gray-700 mb-2">
                    Objet
                  </label>
                  <input
                    required
                    type="text"
                    id="subject"
                    className="w-full px-4 py-3 rounded-lg bg-gray-50 border-2 border-gray-200 focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-all duration-300"
                    placeholder="Sujet de votre message"
                  />
                </div>
  
                <div>
                  <label htmlFor="message" className="block text-sm font-bold text-gray-700 mb-2">
                    Message
                  </label>
                  <textarea
                    required
                    id="message"
                    rows="4"
                    className="w-full px-4 py-3 rounded-lg bg-gray-50 border-2 border-gray-200 focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-all duration-300 resize-none"
                    placeholder="Écrivez votre message ici..."
                  ></textarea>
                </div>
  
                <button
                  type="submit"
                  disabled={formStatus.isSubmitting}
                  className={`w-full bg-orange-500 text-white px-6 py-4 rounded-lg hover:bg-orange-600 transition-all duration-300 flex items-center justify-center group font-bold text-sm uppercase tracking-wide
                    ${formStatus.isSubmitting ? 'opacity-75 cursor-not-allowed' : 'transform hover:-translate-y-0.5'}
                  `}
                >
                  {formStatus.isSubmitting ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                      Envoi en cours...
                    </span>
                  ) : (
                    <span className="flex items-center">
                      Envoyer le message
                      <svg 
                        className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </span>
                  )}
                </button>
  
                {formStatus.isSuccess && (
                  <div className="p-3 bg-green-50 border border-green-200 text-green-700 rounded-lg flex items-center text-sm font-medium">
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Message envoyé avec succès !
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default ContactSection;