import { Linkedin, Twitter, Mail, Phone, MapPin, Calendar, BookOpen, Briefcase, ArrowLeft } from 'lucide-react';
import Layout from '../components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';

const PageBanner = ({ title, subtitle }) => (
    <div className="relative bg-gradient-to-r from-gray-900 to-gray-800 overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341),linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341)] bg-[length:60px_60px] bg-[position:0_0,30px_30px] opacity-20"></div>
        <div className="absolute inset-0 bg-gradient-to-r from-orange-500/20 via-orange-500/10 to-orange-500/20 animate-gradient-x"></div>
        {/* Cercles décoratifs */}
        <div className="absolute top-0 right-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl"></div>
      </div>
      
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <div className="flex items-center justify-center space-x-2 mb-6">
            <div className="h-1 w-12 bg-orange-400 rounded-full"></div>
            <div className="h-1 w-24 bg-orange-500 rounded-full"></div>
            <div className="h-1 w-12 bg-orange-400 rounded-full"></div>
          </div>
          <h1 className="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl">
            {title}
          </h1>
          {subtitle && (
            <p className="mt-4 text-xl text-orange-300 font-medium">
              {subtitle}
            </p>
          )}
        </div>
      </div>
    </div>
);

const MemberDetail = ({ member }) => {
  // Fonction pour créer un HTML sécurisé à partir du texte de la bio
  const createMarkup = (htmlContent) => {
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
    return { __html: sanitizedHtml };
  };

  return (
    <div className="bg-gradient-to-b from-gray-100 to-white min-h-screen">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(#FB923C10_1px,transparent_1px),linear-gradient(to_right,#FB923C10_1px,transparent_1px)] bg-[size:40px_40px]"></div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Colonne de gauche - Photo et informations de contact */}
          <div className="lg:col-span-1">
            <div className="sticky top-8">
              <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-orange-100">
                <div className="relative aspect-[3/4]">
                  <img 
                    src={member.image} 
                    alt={member.name} 
                    className="w-full h-full object-cover object-center"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent"></div>
                  <div className="absolute bottom-0 left-0 right-0 p-8">
                    <h1 className="text-3xl font-bold text-white mb-2">{member.name}</h1>
                    <p className="text-orange-300 text-lg font-medium">{member.role}</p>
                  </div>
                </div>

                <div className="p-8 space-y-6">
                  <div className="flex items-center space-x-4 text-gray-600 hover:text-orange-500 transition-colors group">
                    <Mail className="w-5 h-5 text-orange-500 group-hover:scale-110 transition-transform" />
                    <a href={`mailto:${member.email}`} className="text-lg">
                      {member.email}
                    </a>
                  </div>
                  
                  <div className="flex items-center space-x-4 text-gray-600 hover:text-orange-500 transition-colors group">
                    <Phone className="w-5 h-5 text-orange-500 group-hover:scale-110 transition-transform" />
                    <a href={`tel:${member.phone}`} className="text-lg">
                      {member.phone}
                    </a>
                  </div>

                  <div className="flex items-center space-x-4 text-gray-600 group">
                    <MapPin className="w-5 h-5 text-orange-500 group-hover:scale-110 transition-transform" />
                    <span className="text-lg">{member.location}</span>
                  </div>

                  <div className="pt-6 flex justify-center space-x-6">
                    <a 
                      href={member.linkedin} 
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-4 bg-gray-50 rounded-full hover:bg-orange-50 hover:text-orange-500 transition-all duration-300 hover:scale-110"
                    >
                      <Linkedin className="w-6 h-6" />
                    </a>
                    <a 
                      href={member.twitter} 
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-4 bg-gray-50 rounded-full hover:bg-orange-50 hover:text-orange-500 transition-all duration-300 hover:scale-110"
                    >
                      <Twitter className="w-6 h-6" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Colonne de droite - Biographie et parcours */}
          <div className="lg:col-span-2 space-y-8">
            {/* À propos */}
            <div className="bg-white rounded-2xl shadow-xl p-8 border border-orange-100">
              <div className="flex items-center space-x-4 mb-8">
                <BookOpen className="w-8 h-8 text-orange-500" />
                <h2 className="text-3xl font-bold text-gray-900">À propos</h2>
              </div>
              <div className="prose prose-lg prose-orange max-w-none text-gray-600 space-y-6" 
                   dangerouslySetInnerHTML={createMarkup(member.bio)}>
              </div>
            </div>

            {/* Parcours professionnel */}
            <div className="bg-white rounded-2xl shadow-xl p-8 border border-orange-100">
              <div className="flex items-center space-x-4 mb-8">
                <Briefcase className="w-8 h-8 text-orange-500" />
                <h2 className="text-3xl font-bold text-gray-900">Parcours professionnel</h2>
              </div>
              <div className="space-y-12">
                {member.experience.map((exp, index) => (
                  <div key={index} className="relative pl-12 pb-12 border-l-2 border-orange-200 last:pb-0">
                    <div className="absolute -left-3 top-0 w-6 h-6 bg-white border-4 border-orange-500 rounded-full"></div>
                    <div className="flex items-center space-x-3 mb-4">
                      <Calendar className="w-5 h-5 text-orange-500" />
                      <span className="text-lg font-semibold text-orange-600">{exp.period}</span>
                    </div>
                    <h3 className="text-2xl font-bold text-gray-900 mb-2">{exp.title}</h3>
                    <p className="text-lg text-orange-600 font-medium mb-4">{exp.company}</p>
                    <p className="text-gray-600 text-lg leading-relaxed">{exp.description}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Compétences */}
            {member.skills && (
              <div className="bg-white rounded-2xl shadow-xl p-8 border border-orange-100">
                <h2 className="text-3xl font-bold text-gray-900 mb-8">Compétences</h2>
                <div className="flex flex-wrap gap-4">
                  {member.skills.map((skill, index) => (
                    <span 
                      key={index}
                      className="px-6 py-3 bg-orange-50 text-orange-600 rounded-full font-medium text-lg hover:bg-orange-100 transition-colors cursor-default"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Bibliographie = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // Les données devraient idéalement être chargées en fonction de l'ID
  const memberData = {
    name: "Dr Soufianou MOUSSA",
    role: "Fondateur et conseiller stratégique",
    image: "images/soufianou.png",
    email: "soufianou.moussa@aydees.com",
    phone: "+227 XX XX XX XX",
    location: "Niamey, Niger",
    linkedin: "https://linkedin.com/",
    twitter: "https://twitter.com/",
    bio: "Dr Soufianou MOUSSA est un expert en développement socioéconomique avec plus de 15 années d'expérience dans la recherche et le conseil. Il est titulaire d'un doctorat en sciences économiques et possède une expertise approfondie dans l'analyse des données et l'évaluation des politiques publiques. Passionné par l'innovation et le développement durable, il a fondé Aydees pour apporter des solutions technologiques adaptées aux défis du continent africain.",
    experience: [
      {
        period: "2020 - Présent",
        title: "Fondateur et conseiller stratégique",
        company: "Aydees",
        description: "Direction stratégique de l'entreprise, développement des partenariats et supervision des projets majeurs."
      },
      {
        period: "2015 - 2020",
        title: "Chercheur Senior",
        company: "Institut de Recherche XYZ",
        description: "Conduite de recherches sur le développement économique en Afrique de l'Ouest."
      },
      {
        period: "2010 - 2015",
        title: "Consultant",
        company: "Cabinet International ABC",
        description: "Conseil en développement économique pour les organisations internationales."
      }
    ],
    skills: [
      "Analyse économique",
      "Gestion de projet",
      "Leadership",
      "Innovation sociale",
      "Stratégie d'entreprise",
      "Développement durable"
    ]
  };

  return (
    <Layout>
      <PageBanner 
        title={memberData.name}
        subtitle={memberData.role}
      />
      <MemberDetail member={memberData} />
    </Layout>
  );
};

export default Bibliographie;