import { useState } from 'react';
import Layout from '../components/Layout';

const PageBanner = ({ title, subtitle }) => (
    <div className="relative bg-gradient-to-r from-gray-900 to-gray-800 overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341),linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341)] bg-[length:60px_60px] bg-[position:0_0,30px_30px] opacity-20"></div>
        <div className="absolute inset-0 bg-gradient-to-r from-orange-500/20 via-orange-500/10 to-orange-500/20 animate-gradient-x"></div>
        {/* Cercles décoratifs */}
        <div className="absolute top-0 right-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl"></div>
      </div>
      
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <div className="flex items-center justify-center space-x-2 mb-6">
            <div className="h-1 w-12 bg-orange-400 rounded-full"></div>
            <div className="h-1 w-24 bg-orange-500 rounded-full"></div>
            <div className="h-1 w-12 bg-orange-400 rounded-full"></div>
          </div>
          <h1 className="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl">
            {title}
          </h1>
          {subtitle && (
            <p className="mt-4 text-xl text-orange-300 font-medium">
              {subtitle}
            </p>
          )}
        </div>
      </div>
    </div>
);

const RejoignezNous = () => {
  const [formStatus, setFormStatus] = useState({
    isSubmitting: false,
    isSuccess: false,
    error: null
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus({ ...formStatus, isSubmitting: true });
    setTimeout(() => {
      setFormStatus({
        isSubmitting: false,
        isSuccess: true,
        error: null
      });
      setTimeout(() => setFormStatus({
        isSubmitting: false,
        isSuccess: false,
        error: null
      }), 3000);
    }, 1000);
  };

  return (
    <Layout>
      <PageBanner 
        title="Rejoignez-nous" 
        subtitle="Nous sommes ravis de vous accueillir. Remplissez le formulaire ci-dessous pour nous contacter."
      />
      
      <div className="min-h-screen bg-gray-50 relative">
        {/* Motifs de fond technologiques */}
        <div className="absolute inset-0 overflow-hidden">
          {/* Grille */}
          <div className="absolute inset-0 bg-[linear-gradient(#20202010_1px,transparent_1px),linear-gradient(to_right,#20202010_1px,transparent_1px)] bg-[size:40px_40px]"></div>
          {/* Cercles décoratifs */}
          <div className="absolute top-20 left-10 w-72 h-72 bg-orange-500/5 rounded-full blur-3xl"></div>
          <div className="absolute bottom-20 right-10 w-96 h-96 bg-blue-500/5 rounded-full blur-3xl"></div>
        </div>

        {/* Contenu principal */}
        <div className="relative flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg p-8 w-full max-w-3xl">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-bold text-gray-700 mb-2">
                  Prénom & Nom
                </label>
                <input
                  required
                  type="text"
                  id="name"
                  className="w-full px-4 py-3 rounded-lg bg-white/50 border-2 border-gray-200 focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-all duration-300"
                  placeholder="Entrez votre nom complet"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-bold text-gray-700 mb-2">
                  E-Mail
                </label>
                <input
                  required
                  type="email"
                  id="email"
                  className="w-full px-4 py-3 rounded-lg bg-white/50 border-2 border-gray-200 focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-all duration-300"
                  placeholder="Entrez votre adresse email"
                />
              </div>

              <div>
                <label htmlFor="subject" className="block text-sm font-bold text-gray-700 mb-2">
                  Objet
                </label>
                <input
                  required
                  type="text"
                  id="subject"
                  className="w-full px-4 py-3 rounded-lg bg-white/50 border-2 border-gray-200 focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-all duration-300"
                  placeholder="Sujet de votre message"
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-bold text-gray-700 mb-2">
                  Message
                </label>
                <textarea
                  required
                  id="message"
                  rows="6"
                  className="w-full px-4 py-3 rounded-lg bg-white/50 border-2 border-gray-200 focus:bg-white focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-all duration-300 resize-none"
                  placeholder="Écrivez votre message ici..."
                ></textarea>
              </div>

              <button
                type="submit"
                disabled={formStatus.isSubmitting}
                className={`w-full bg-gradient-to-r from-orange-500 to-orange-600 text-white px-6 py-4 rounded-lg hover:from-orange-600 hover:to-orange-700 transition-all duration-300 flex items-center justify-center group font-bold text-sm uppercase tracking-wide
                  ${formStatus.isSubmitting ? 'opacity-75 cursor-not-allowed' : 'transform hover:-translate-y-0.5'}
                `}
              >
                {formStatus.isSubmitting ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    Envoi en cours...
                  </span>
                ) : (
                  <span className="flex items-center">
                    Envoyer le message
                    <svg 
                      className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </span>
                )}
              </button>

              {formStatus.isSuccess && (
                <div className="p-3 bg-green-50 border border-green-200 text-green-700 rounded-lg flex items-center text-sm font-medium">
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  Message envoyé avec succès !
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RejoignezNous;