import React, { useState, useRef, useEffect } from 'react';
import { ArrowLeft, ArrowRight, Building, Users, Globe, Briefcase, LandPlot } from 'lucide-react';
import Layout from '../components/Layout';


// [Le composant PageBanner reste identique]
const PageBanner = () => (
    <div className="relative bg-gradient-to-r from-gray-900 to-gray-800 overflow-hidden">
        <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341),linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341)] bg-[length:60px_60px] bg-[position:0_0,30px_30px] opacity-20"></div>
            <div className="absolute inset-0 bg-gradient-to-r from-orange-500/20 via-orange-500/10 to-orange-500/20 animate-gradient-x"></div>
            <div className="absolute -left-1/4 -top-1/4 w-1/2 h-1/2 bg-orange-500/20 rounded-full blur-3xl"></div>
            <div className="absolute -right-1/4 -bottom-1/4 w-1/2 h-1/2 bg-orange-500/20 rounded-full blur-3xl"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 py-24 sm:px-6 lg:px-8">
            <div className="text-center">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white mb-6">
                    Nos Partenaires
                </h1>
                <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                    Un écosystème d'excellence pour votre réussite
                </p>
            </div>
        </div>
    </div>
);

// [Le composant LogoCarousel reste identique]
const LogoCarousel = ({ category, logos }) => {
    const [isHovered, setIsHovered] = useState(false);
    const scrollRef = useRef(null);

    const scroll = (direction) => {
        if (scrollRef.current) {
            const scrollAmount = direction === 'left' ? -400 : 400;
            scrollRef.current.scrollBy({
                left: scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        const scrollContainer = scrollRef.current;
        let scrollInterval;

        const startScroll = () => {
            scrollInterval = setInterval(() => {
                if (!isHovered && scrollContainer) {
                    scrollContainer.scrollLeft += 1;
                    if (scrollContainer.scrollLeft >= (scrollContainer.scrollWidth - scrollContainer.clientWidth)) {
                        scrollContainer.scrollLeft = 0;
                    }
                }
            }, 20);
        };

        startScroll();

        return () => clearInterval(scrollInterval);
    }, [isHovered]);

    return (
        <div className="bg-white rounded-xl shadow-lg p-8 mt-8">
            <div className="flex items-center mb-6">
                <category.icon className="w-8 h-8 text-orange-500 mr-3" />
                <h3 className="text-2xl font-bold text-gray-900">{category.name}</h3>
            </div>

            <div className="relative" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <button
                    onClick={() => scroll('left')}
                    className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/90 p-2 rounded-full shadow-lg hover:bg-orange-50"
                >
                    <ArrowLeft className="w-6 h-6 text-orange-500" />
                </button>

                <div ref={scrollRef} className="flex overflow-x-hidden gap-8 py-4 px-12">
                    {logos.map((logo, index) => (
                        <div
                            key={index}
                            className="flex-shrink-0 w-40 h-24 bg-white rounded-lg shadow-sm border border-gray-100 flex items-center justify-center p-4 hover:shadow-md transition-shadow"
                        >
                            <div
                                className="flex-shrink-0 w-40 h-24 bg-white rounded-lg shadow-sm border border-gray-100 flex items-center justify-center p-4 hover:shadow-md transition-shadow"
                                onContextMenu={(e) => e.preventDefault()}
                            >
                                <img
                                    src={logo.name}
                                    alt={`Logo ${index}`}
                                    draggable="false"
                                    onContextMenu={(e) => e.preventDefault()}
                                    style={{
                                        WebkitUserSelect: 'none',
                                        userSelect: 'none',
                                        pointerEvents: 'none'
                                    }}
                                    className="max-w-full max-h-full object-contain"
                                />
                            </div>
                        </div>
                    ))}
                </div>

                <button
                    onClick={() => scroll('right')}
                    className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/90 p-2 rounded-full shadow-lg hover:bg-orange-50"
                >
                    <ArrowRight className="w-6 h-6 text-orange-500" />
                </button>
            </div>
        </div>
    );
};


function importAll(r) {
    return r.keys().map(r);
}

const NosPartenaires = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const images = importAll(require.context('./../../public/10', false, /\.(png|jpe?g|gif)$/));
        const images1 = importAll(require.context('./../../public/11', false, /\.(png|jpe?g|gif)$/));
        const images2 = importAll(require.context('./../../public/12', false, /\.(png|jpe?g|gif)$/));
        const images3 = importAll(require.context('./../../public/13', false, /\.(png|jpe?g|gif)$/));
        const images4 = importAll(require.context('./../../public/14', false, /\.(png|jpe?g|gif)$/));
        // Transformer les images en format exploitable dans le code
        const logos = images.map((image) => ({
            name: image // Plus besoin de générer un nom, utiliser directement l'URL
        }));
        const logos1 = images1.map((image) => ({
            name: image // Plus besoin de générer un nom, utiliser directement l'URL
        }));
        const logos2 = images2.map((image) => ({
            name: image // Plus besoin de générer un nom, utiliser directement l'URL
        }));
        const logos3 = images3.map((image) => ({
            name: image // Plus besoin de générer un nom, utiliser directement l'URL
        }));
        const logos4 = images4.map((image) => ({
            name: image // Plus besoin de générer un nom, utiliser directement l'URL
        }));
        const categoryData = [

            {
                name: "Associations et ONG internationales",
                icon: Globe,
                logos: logos,
            },
            {
                name: "Organisations Internationales de Développement",
                icon: Building,
                logos: logos1
            },
            {
                name: "Associations et ONG Nationales",
                icon: Users,
                logos: logos2
            },
            {
                name: "Secteur privé national et international",
                icon: Briefcase,
                logos: logos3
            },
            {
                name: "Structures publiques et parapubliques",
                icon: LandPlot,
                logos: logos4
            }
        ];

        setCategories(categoryData);
    }, []);

    console.log("Categories:", categories);

    return (
        <Layout>
            <PageBanner />
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                <div className="text-center max-w-3xl mx-auto mb-16">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">Un Réseau d'Excellence</h2>
                    <p className="text-gray-600">
                        Nous collaborons avec les meilleurs acteurs du secteur pour vous offrir des solutions innovantes et performantes.
                    </p>
                </div>
                {categories.map((category, index) => (
                    <LogoCarousel key={index} category={category} logos={category.logos || []} />
                ))}
            </div>
        </Layout>
    );
};
export default NosPartenaires;