import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronDown, Search } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const TopSection = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    console.log('Recherche:', searchQuery);
  };

  return (
    <div className="bg-white py-2 border-b">
      <div className="max-w-7xl mx-auto flex justify-between items-center px-4">
        {/* Social Media Icons */}
        <div className="flex space-x-2">
          <a href="#" className="w-8 h-8 flex items-center justify-center rounded-full bg-[#3b5998] text-white hover:opacity-90 transition-opacity">
            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
              <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" />
            </svg>
          </a>
          <a href="#" className="w-8 h-8 flex items-center justify-center rounded-full bg-[#0077b5] text-white hover:opacity-90 transition-opacity">
            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
              <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
            </svg>
          </a>
          <a href="#" className="w-8 h-8 flex items-center justify-center rounded-full bg-[#1DA1F2] text-white hover:opacity-90 transition-opacity">
            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
              <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
            </svg>
          </a>
        </div>

        {/* Search Bar */}
        <div className="relative">
          {isSearchOpen ? (
            <form onSubmit={handleSearch} className="flex items-center">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Rechercher..."
                className="pl-4 pr-10 py-1 border rounded-full text-sm focus:outline-none focus:border-orange-500 w-64"
              />
              <button
                type="button"
                onClick={() => setIsSearchOpen(false)}
                className="absolute right-2 p-1 hover:bg-gray-100 rounded-full"
              >
                <X className="w-4 h-4 text-gray-500" />
              </button>
            </form>
          ) : (
            <button
              onClick={() => setIsSearchOpen(true)}
              className="p-1.5 hover:bg-gray-100 rounded-full transition-colors"
            >
              <Search className="w-5 h-5 text-gray-600" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const LogoSection = () => {
  const [logos, setLogos] = useState([
    {
      id: 1,
      src: "/images/niger-hosting.png",
      alt: "Niger Hosting",
      link: "https://nigerhosting.com"
    },
    {
      id: 2,
      src: "/images/niger-emploi-new.gif",
      alt: "NigerEmploi",
      link: "https://nigeremploi.com"
    },
    {
      id: 3,
      src: "/images/niger-tic.png",
      alt: "Niger-Tic.Com",
      link: "https://niger-tic.com"
    },
    {
      id: 4,
      src: "images/niger_ao-new.png",
      alt: "Niger AO",
      link: "https://nigerao.com"
    },
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setLogos(prevLogos => {
        const newLogos = [...prevLogos];
        const firstLogo = newLogos.shift();
        newLogos.push(firstLogo);
        return newLogos;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-white py-3 border-b">
      <div className="max-w-7xl mx-auto flex items-center justify-between px-4">
        <Link to="/" className="w-40">
          <img
            src="/images/logo-aydees.png"
            alt="Aydees"
            className="h-12 w-auto object-contain"
          />
        </Link>

        <div className="flex-1 ml-4">
          <div className="relative flex justify-end">
            <div className="flex space-x-4 overflow-hidden">
              {logos.slice(0, 3).map((logo) => (
                <a
                  key={logo.id}
                  href={logo.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[150px] h-[50px] rounded-md flex items-center justify-center shadow-sm transition-all duration-700 ease-in-out hover:shadow-md"
                >
                  <img
                    src={logo.src}
                    alt={logo.alt}
                    className="max-w-[130px] max-h-[40px] object-contain"
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const menuItems = [
  {
    name: 'ACCUEIL',
    path: '/'
  },
  {
    name: 'QUI SOMMES-NOUS ?',
    path: '#',
    submenu: [
      { name: 'Equipe', path: '/notre-equipe' },
      { name: 'Nous rejoindre', path: '/rejoignez-nous' },
      { name: 'Nos partenaires', path: '/nos-partennaires' },
      { name: 'Actualités', path: '/about/actualites' }
    ]
  },
  {
    name: 'PRODUITS & SERVICES',
    path: '/services',
    submenu: [
      // { name: 'Etudes & Conseils', path: '/services/etudes-conseils' },
      {
        name: 'Niger Emploi',
        externalLink: 'https://nigeremploi.com',
        target: '_blank'
      },
      {
        name: 'Niger Hosting',
        externalLink: 'https://nigerhosting.com',
        target: '_blank'
      },
      {
        name: 'Niger AO',
        externalLink: 'https://nigerao.com',
        target: '_blank'
      },
      { name: 'ICT Consulting', path: '/ict-consulting' },
      { name: 'RH & Formation', path: '/rh-formation' }
    ]
  },
  {
    name: 'ACTIVITÉS',
    path: '/activities',
    submenu: [
      { name: 'Etudes et Conseils en informatique', path: '/etude-conseil' },
      { name: 'Formation', path: '/activities/formation' },
      { name: 'Ressources Humaines', path: '/resources-humaines' },
      { name: 'Web & Média: Net Agence', path: '/web-media' }
    ]
  },
  {
    name: 'RÉFÉRENCES',
    path: '/references'
  }
];

const MainNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const location = useLocation();

  const MenuItem = ({ item }) => {
    if (item.externalLink) {
      return (
        <a
          href={item.externalLink}
          target={item.target || '_self'}
          rel={item.target === '_blank' ? "noopener noreferrer" : ""}
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-orange-50 hover:text-orange-500 transition-colors flex items-center group"
        >
          <span>{item.name}</span>
          {item.target === '_blank' && (
            <svg
              className="w-4 h-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          )}
        </a>
      );
    }

    return (
      <Link
        to={item.path}
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-orange-50 hover:text-orange-500 transition-colors"
      >
        {item.name}
      </Link>
    );
  };

  return (
    <nav className="bg-gray-50 shadow-sm">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center">
          <div className="hidden md:flex">
            {menuItems.map((item) => (
              <div
                key={item.name}
                className="relative group"
                onMouseEnter={() => setOpenSubmenu(item.name)}
                onMouseLeave={() => setOpenSubmenu(null)}
              >
                {item.externalLink ? (
                  <a
                    href={item.externalLink}
                    target={item.target || '_self'}
                    rel={item.target === '_blank' ? "noopener noreferrer" : ""}
                    className={`
                      flex items-center px-4 py-4 text-sm font-medium text-gray-700
                      hover:text-orange-500 transition-colors relative
                      group-hover:after:content-['']
                      group-hover:after:absolute
                      group-hover:after:bottom-0
                      group-hover:after:left-0
                      group-hover:after:w-full
                      group-hover:after:h-0.5
                      group-hover:after:bg-orange-500
                    `}
                  >
                    {item.name}
                    {item.submenu && (
                      <ChevronDown className="w-4 h-4 ml-1 transform group-hover:rotate-180 transition-transform" />
                    )}
                  </a>
                ) : (
                  <Link
                    to={item.path}
                    className={`
                      flex items-center px-4 py-4 text-sm font-medium
                      ${location.pathname === item.path ? 'text-orange-500' : 'text-gray-700'}
                      hover:text-orange-500 transition-colors relative
                      group-hover:after:content-['']
                      group-hover:after:absolute
                      group-hover:after:bottom-0
                      group-hover:after:left-0
                      group-hover:after:w-full
                      group-hover:after:h-0.5
                      group-hover:after:bg-orange-500
                    `}
                  >
                    {item.name}
                    {item.submenu && (
                      <ChevronDown className="w-4 h-4 ml-1 transform group-hover:rotate-180 transition-transform" />
                    )}
                  </Link>
                )}

                {item.submenu && openSubmenu === item.name && (
                  <div className="absolute left-0 mt-0 w-56 bg-white rounded-b-lg shadow-lg py-1 z-50 border-t-2 border-orange-500">
                    {item.submenu.map((subitem) => (
                      <MenuItem key={subitem.name} item={subitem} />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          <Link
            to="/rejoignez-nous"
            className="bg-orange-500 text-white px-6 py-2 text-sm font-medium rounded hover:bg-orange-600 transition-colors shadow-sm hover:shadow-md"
          >
            NOUS REJOINDRE
          </Link>

          <button
            className="md:hidden p-2 hover:bg-gray-100 rounded-lg"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
        </div>

        {/* Version mobile */}
        {isMenuOpen && (
          <div className="md:hidden py-2">
            {menuItems.map((item) => (
              <div key={item.name}>
                <button
                  onClick={() => setOpenSubmenu(openSubmenu === item.name ? null : item.name)}
                  className="w-full flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                >
                  {item.externalLink ? (
                    <a
                      href={item.externalLink}
                      target={item.target || '_self'}
                      rel={item.target === '_blank' ? "noopener noreferrer" : ""}
                      className="flex-1 flex items-center"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {item.name}
                      {item.target === '_blank' && (
                        <svg
                          className="w-4 h-4 ml-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          />
                        </svg>
                      )}
                    </a>
                  ) : (
                    <Link to={item.path} className="flex-1" onClick={(e) => e.stopPropagation()}>
                      {item.name}
                    </Link>
                  )}
                  {item.submenu && (
                    <ChevronDown
                      className={`w-4 h-4 transform transition-transform ${openSubmenu === item.name ? 'rotate-180' : ''
                        }`}
                    />
                  )}
                </button>

                {item.submenu && openSubmenu === item.name && (
                  <div className="bg-gray-50 py-2">
                    {item.submenu.map((subitem) => (
                      <div key={subitem.name} className="px-4">
                        <MenuItem item={subitem} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <div className="px-4 py-2">
              <Link
                to="/rejoignez-nous"
                className="block w-full bg-orange-500 text-white px-4 py-2 text-sm font-medium rounded hover:bg-orange-600 transition-colors text-center"
              >
                NOUS REJOINDRE
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

const Header = () => {
  return (
    <header className="fixed w-full z-50 bg-white">
      <TopSection />
      <LogoSection />
      <MainNav />
    </header>
  );
};

export default Header;