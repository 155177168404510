import React, { useState, useEffect } from 'react';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function ImageSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const slides = [
    {
      image: "images/innover-new-mac.jpg",
      title: "Participer, Innover & Evaluer",
      subtitle: "",
      bgColor: "from-gray-900/60 to-gray-900/60"
    },
    {
      image: "images/etude_conseil_0.jpg",
      title: "Études & Conseils",
      subtitle: "en développement",
      description: "Nos équipes pluridisciplinaires sont composées d'experts passionnés par la communication & le digital.",
      bgColor: "from-gray-900/60 to-gray-900/60"
    },
    {
      image: "images/reseau-new.jpg",
      title: "RÉSEAUX & TÉLÉCOM",
      subtitle: "Réseaux LAN - Audit des réseaux télécoms",
      description: "Vidéo surveillance et contrôle d'accès.",
      bgColor: "from-gray-900/60 to-gray-900/60"
    }
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="relative w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px]">
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="flex h-full transition-transform duration-700 ease-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div key={index} className="w-full h-full flex-shrink-0 relative">
              {/* Image de fond */}
              <img
                src={slide.image}
                alt={slide.title}
                className="w-full h-full object-cover"
              />
              {/* Overlay uniforme */}
              <div className={`absolute inset-0 bg-gradient-to-r ${slide.bgColor}`}></div>
              
              {/* Contenu du slide */}
              <div className="absolute inset-0 overflow-hidden">
                <div className="max-w-7xl mx-auto px-2 sm:px-4 h-full flex items-center justify-center">
                  <div className="text-center w-full sm:max-w-4xl">
                    {/* Titre principal */}
                    <h1 className="text-xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-white tracking-tight mb-2 sm:mb-4 md:mb-6 px-2 sm:px-0">
                      {slide.title.split('&').map((part, i) => (
                        <React.Fragment key={i}>
                          {i > 0 && (
                            <span className="text-orange-500 inline-block px-1 sm:px-2">&</span>
                          )}
                          <span className="inline-block">{part.trim()}</span>
                        </React.Fragment>
                      ))}
                    </h1>

                    {/* Sous-titre */}
                    {slide.subtitle && (
                      <h2 className="text-sm sm:text-xl md:text-2xl lg:text-3xl font-medium text-orange-500 mb-2 sm:mb-4 md:mb-6 tracking-wide px-2 sm:px-0">
                        {slide.subtitle}
                      </h2>
                    )}

                    {/* Description - Cachée sur très petits écrans */}
                    {slide.description && (
                      <p className="hidden sm:block text-base sm:text-lg md:text-xl lg:text-2xl text-white/90 mb-4 sm:mb-6 md:mb-10 leading-relaxed max-w-3xl mx-auto px-2 sm:px-4">
                        {slide.description}
                      </p>
                    )}

                    {/* Boutons */}
                    <div className="flex flex-col sm:flex-row justify-center items-center gap-2 sm:gap-4 md:gap-6 px-4" >
                      <button className="w-full sm:w-auto bg-orange-500 text-white px-4 sm:px-6 md:px-8 py-2 sm:py-3 md:py-4 rounded-full hover:bg-orange-600 transition-colors text-xs sm:text-base md:text-lg font-medium whitespace-nowrap" onClick={()=>navigate('/ict-consulting')}>
                        Découvrir nos services
                      </button>
                      <button className="w-full sm:w-auto border-2 border-white text-white px-4 sm:px-6 md:px-8 py-2 sm:py-3 md:py-4 rounded-full hover:bg-white/10 transition-colors text-xs sm:text-base md:text-lg font-medium whitespace-nowrap" onClick={()=>navigate('/rejoignez-nous')}>
                        Nous contacter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Boutons de navigation - Masqués sur mobile */}
      {!isMobile && (
        <>
          <button
            onClick={prevSlide}
            className="absolute left-2 sm:left-4 md:left-6 top-1/2 -translate-y-1/2 bg-black/20 hover:bg-black/40 p-2 sm:p-3 md:p-4 rounded-full transition-colors z-10 group hidden sm:block"
          >
            <ArrowLeft className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 text-white group-hover:scale-110 transition-transform" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-2 sm:right-4 md:right-6 top-1/2 -translate-y-1/2 bg-black/20 hover:bg-black/40 p-2 sm:p-3 md:p-4 rounded-full transition-colors z-10 group hidden sm:block"
          >
            <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 text-white group-hover:scale-110 transition-transform" />
          </button>
        </>
      )}

      {/* Indicateurs de slide */}
      <div className="absolute bottom-2 sm:bottom-4 md:bottom-8 left-1/2 -translate-x-1/2 flex gap-1.5 sm:gap-3 z-10">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-1.5 h-1.5 sm:w-3 sm:h-3 md:w-4 md:h-4 rounded-full transition-all duration-300 ${
              index === currentIndex 
                ? 'bg-white scale-100' 
                : 'bg-white/50 scale-75 hover:scale-90'
            }`}
          />
        ))}
      </div>
    </div>
  );
}