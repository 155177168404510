import React, { useState } from 'react';
import { BookOpen, Users, Award, Calendar, Clock, ChevronDown, CheckCircle, Brain, ShieldCheck, Database, Code, Globe, FileSpreadsheet, ArrowRight } from 'lucide-react';
import Layout from '../components/Layout';

const PageBanner = () => (
  <div className="relative bg-gradient-to-r from-gray-900 to-gray-800 overflow-hidden">
    <div className="absolute inset-0">
      <div className="absolute inset-0 bg-[linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341),linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341)] bg-[length:60px_60px] bg-[position:0_0,30px_30px] opacity-20"></div>
      <div className="absolute inset-0 bg-gradient-to-r from-orange-500/20 via-purple-500/20 to-blue-500/20 animate-gradient-x"></div>
      <div className="absolute top-0 right-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl"></div>
      <div className="absolute bottom-0 left-0 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl"></div>
    </div>
    
    <div className="relative max-w-7xl mx-auto px-4 py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <div className="flex items-center justify-center space-x-2 mb-6">
          <div className="h-1 w-8 bg-orange-400 rounded-full"></div>
          <div className="h-1 w-20 bg-orange-500 rounded-full"></div>
          <div className="h-1 w-8 bg-orange-400 rounded-full"></div>
        </div>
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white mb-6">
          RH & Formation
        </h1>
        <p className="text-xl text-orange-200 max-w-3xl mx-auto">
          Développez vos compétences avec des formations professionnelles de haute qualité
        </p>
        <div className="mt-8 flex justify-center space-x-4">
          <button className="px-8 py-3 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition-all shadow-lg hover:shadow-xl transform hover:-translate-y-0.5">
            Voir nos formations
          </button>
          <button className="px-8 py-3 bg-white/10 text-white rounded-lg font-semibold hover:bg-white/20 transition-all backdrop-blur-sm">
            Contactez-nous
          </button>
        </div>
      </div>
    </div>
  </div>
);

const Formation = ({ icon: Icon, title, duration, level, description, modules, isOpen, onToggle }) => (
  <div className="bg-white rounded-2xl shadow-lg border border-orange-100 overflow-hidden transition-all">
    <div 
      className="p-6 cursor-pointer hover:bg-gray-50 transition-colors"
      onClick={onToggle}
    >
      <div className="flex items-start space-x-4">
        <div className="w-12 h-12 rounded-xl bg-orange-50 flex items-center justify-center flex-shrink-0">
          <Icon className="w-6 h-6 text-orange-500" />
        </div>
        <div className="flex-1">
          <h3 className="text-xl font-bold text-gray-900 mb-2">{title}</h3>
          <div className="flex flex-wrap gap-4 text-sm text-gray-600">
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              {duration}
            </div>
            <div className="flex items-center">
              <Award className="w-4 h-4 mr-1" />
              {level}
            </div>
          </div>
          <p className="mt-2 text-gray-600">{description}</p>
        </div>
        <ChevronDown 
          className={`w-6 h-6 text-gray-400 transform transition-transform ${isOpen ? 'rotate-180' : ''}`} 
        />
      </div>
    </div>
    
    {isOpen && (
      <div className="px-6 pb-6">
        <div className="pt-4 border-t">
          <h4 className="text-lg font-semibold text-gray-900 mb-4">Programme :</h4>
          <div className="grid gap-3">
            {modules.map((module, index) => (
              <div key={index} className="flex items-start space-x-3">
                <CheckCircle className="w-5 h-5 text-orange-500 flex-shrink-0 mt-0.5" />
                <span className="text-gray-600">{module}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    )}
  </div>
);

const WhyChooseUs = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
    <div className="bg-white p-6 rounded-xl shadow-lg border border-orange-100">
      <div className="w-12 h-12 bg-orange-50 rounded-lg flex items-center justify-center mb-4">
        <Users className="w-6 h-6 text-orange-500" />
      </div>
      <h3 className="text-xl font-bold text-gray-900 mb-2">Formateurs Experts</h3>
      <p className="text-gray-600">
        Des professionnels expérimentés qui partagent leur expertise du terrain.
      </p>
    </div>
    
    <div className="bg-white p-6 rounded-xl shadow-lg border border-orange-100">
      <div className="w-12 h-12 bg-orange-50 rounded-lg flex items-center justify-center mb-4">
        <BookOpen className="w-6 h-6 text-orange-500" />
      </div>
      <h3 className="text-xl font-bold text-gray-900 mb-2">Formation Pratique</h3>
      <p className="text-gray-600">
        Apprentissage basé sur des cas concrets et des projets réels.
      </p>
    </div>
    
    <div className="bg-white p-6 rounded-xl shadow-lg border border-orange-100">
      <div className="w-12 h-12 bg-orange-50 rounded-lg flex items-center justify-center mb-4">
        <Award className="w-6 h-6 text-orange-500" />
      </div>
      <h3 className="text-xl font-bold text-gray-900 mb-2">Certification</h3>
      <p className="text-gray-600">
        Obtenez une certification reconnue à la fin de votre formation.
      </p>
    </div>
  </div>
);

const RhFormation = () => {
  const [openFormation, setOpenFormation] = useState(null);

  const formations = [
    {
      icon: Database,
      title: "Analyse de Données",
      duration: "5 jours",
      level: "Intermédiaire",
      description: "Maîtrisez l'analyse de données avec les outils modernes",
      modules: [
        "Introduction à l'analyse de données",
        "Collecte et nettoyage des données",
        "Visualisation avec Python et Tableau",
        "Analyse statistique",
        "Machine Learning basics",
        "Projets pratiques"
      ]
    },
    {
      icon: Code,
      title: "Python Programming",
      duration: "10 jours",
      level: "Débutant à Avancé",
      description: "Apprenez à programmer en Python de zéro à expert",
      modules: [
        "Fondamentaux de Python",
        "Structures de données",
        "Programmation orientée objet",
        "Gestion des fichiers et bases de données",
        "Framework Django",
        "Projets pratiques"
      ]
    },
    {
      icon: ShieldCheck,
      title: "Sécurité Informatique",
      duration: "5 jours",
      level: "Avancé",
      description: "Protégez vos systèmes contre les menaces",
      modules: [
        "Fondamentaux de la cybersécurité",
        "Analyse des vulnérabilités",
        "Sécurité des réseaux",
        "Cryptographie",
        "Sécurité Web",
        "Tests d'intrusion"
      ]
    },
    {
      icon: Brain,
      title: "KoboToolbox",
      duration: "3 jours",
      level: "Intermédiaire",
      description: "Collectez et analysez des données avec KoboToolbox",
      modules: [
        "Introduction à KoboToolbox",
        "Création de formulaires",
        "Collecte de données",
        "Analyse et visualisation",
        "Gestion des projets",
        "Cas pratiques"
      ]
    },
    {
      icon: Code,
      title: "Java Play Framework",
      duration: "5 jours",
      level: "Avancé",
      description: "Développez des applications web avec Play Framework",
      modules: [
        "Introduction à Play Framework",
        "Architecture MVC",
        "Gestion des routes",
        "Base de données avec JPA",
        "API REST",
        "Déploiement"
      ]
    },
    {
      icon: Globe,
      title: "CMS (WordPress & Drupal)",
      duration: "5 jours",
      level: "Débutant à Intermédiaire",
      description: "Créez et gérez des sites web professionnels",
      modules: [
        "Installation et configuration",
        "Gestion du contenu",
        "Thèmes et templates",
        "Extensions et modules",
        "SEO et performance",
        "Maintenance et sécurité"
      ]
    },
    {
      icon: FileSpreadsheet,
      title: "Bureautique Professionnelle",
      duration: "5 jours",
      level: "Tous niveaux",
      description: "Maîtrisez la suite Office pour le travail professionnel",
      modules: [
        "Microsoft Word avancé",
        "Excel et analyses de données",
        "PowerPoint professionnel",
        "Outlook et gestion du temps",
        "Access basics",
        "Collaboration et partage"
      ]
    }
  ];

  return (
    <Layout>
      <PageBanner />
      
      {/* Pourquoi nous choisir */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Pourquoi Nous Choisir ?</h2>
            <p className="text-gray-600">Une formation de qualité pour une expertise reconnue</p>
          </div>
          <WhyChooseUs />
        </div>
      </section>

      {/* Formations */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Nos Formations</h2>
            <p className="text-gray-600">Des formations adaptées à vos besoins et à votre niveau</p>
          </div>
          <div className="space-y-6">
            {formations.map((formation, index) => (
              <Formation 
                key={index}
                {...formation}
                isOpen={openFormation === index}
                onToggle={() => setOpenFormation(openFormation === index ? null : index)}
              />
            ))}
          </div>
        </div>
      </section>

      {/* CTA */}
      <section className="py-20 bg-gradient-to-r from-gray-900 to-gray-800 relative overflow-hidden">
        <div className="absolute inset-0 bg-orange-500/10"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">Prêt à développer vos compétences ?</h2>
          <p className="text-lg text-gray-300 mb-8 max-w-2xl mx-auto">
            Inscrivez-vous à nos formations et boostez votre carrière professionnelle
          </p>
          <div className="flex justify-center space-x-4">
            <button className="px-8 py-3 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition-all shadow-lg hover:shadow-xl flex items-center space-x-2">
              <span>S'inscrire maintenant</span>
              <ArrowRight className="w-5 h-5" />
            </button>
            <button className="px-8 py-3 bg-white/10 text-white rounded-lg font-semibold hover:bg-white/20 transition-all backdrop-blur-sm">
              Demander plus d'informations
            </button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RhFormation;