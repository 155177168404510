import { Linkedin, Twitter } from 'lucide-react';
import Layout from '../components/Layout';
import { useNavigate, Link } from 'react-router-dom';

const PageBanner = ({ title, subtitle }) => (
    <div className="relative bg-gradient-to-r from-gray-900 to-gray-800 overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341),linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341)] bg-[length:60px_60px] bg-[position:0_0,30px_30px] opacity-20"></div>
        <div className="absolute inset-0 bg-gradient-to-r from-orange-500/20 via-orange-500/10 to-orange-500/20 animate-gradient-x"></div>
        {/* Cercles décoratifs */}
        <div className="absolute top-0 right-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl"></div>
      </div>
      
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <div className="flex items-center justify-center space-x-2 mb-6">
            <div className="h-1 w-12 bg-orange-400 rounded-full"></div>
            <div className="h-1 w-24 bg-orange-500 rounded-full"></div>
            <div className="h-1 w-12 bg-orange-400 rounded-full"></div>
          </div>
          <h1 className="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl">
            {title}
          </h1>
          {subtitle && (
            <p className="mt-4 text-xl text-orange-300 font-medium">
              {subtitle}
            </p>
          )}
        </div>
      </div>
    </div>
);

const TeamMember = ({ member }) => {
    const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/bibliographie`);
  };
  return (
    <div className="group cursor-pointer"  onClick={handleCardClick}>
    
      <div className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl">
        <div className="relative h-[400px] overflow-hidden">
          <img 
            src={member.image} 
            alt={member.name} 
            className="w-full h-full object-cover object-center"
            loading="lazy"
          />
          
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/60 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          
          <div className="absolute bottom-28 left-0 w-full flex justify-center space-x-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <a 
              href={member.linkedin} 
              target="_blank"
              rel="noopener noreferrer"
              className="p-3 bg-white rounded-full hover:bg-gray-100 transition-colors duration-300"
            >
              <Linkedin className="w-5 h-5 text-gray-700" />
            </a>
            <a 
              href={member.twitter} 
              target="_blank"
              rel="noopener noreferrer"
              className="p-3 bg-white rounded-full hover:bg-gray-100 transition-colors duration-300"
            >
              <Twitter className="w-5 h-5 text-gray-700" />
            </a>
          </div>

          <div className="absolute bottom-0 left-0 right-0 bg-white p-6 transform transition-transform duration-300 group-hover:translate-y-0">
            <h3 className="text-xl font-bold text-gray-900 mb-2">
              {member.name}
            </h3>
            <p className="text-orange-600 font-medium mb-2">
              {member.role}
            </p>
            <div className="h-1 w-12 bg-orange-500 rounded-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Equipe = () => {
  const teamMembers = [
    {
      name: "Dr Soufianou MOUSSA",
      role: "Fondateur et conseiller stratégique",
      image: "images/soufianou.png",
      linkedin: "https://linkedin.com/",
      twitter: "https://twitter.com/"
    },
    {
      name: "LAOUALI KADADE Mahamadou Aminou",
      role: "Directeur des Opérations",
      image: "images/aminou.png",
      linkedin: "https://linkedin.com/",
      twitter: "https://twitter.com/"
    },
    {
      name: "Hamza Na-Allah Abdoul Rachid",
      role: "Responsable Administratif et Financier",
      image: "images/rachid.png",
      linkedin: "https://linkedin.com/",
      twitter: "https://twitter.com/"
    },
    {
      name: "Seydou Moussa Boubacar",
      role: "IT Manager",
      image: "images/bouba.png",
      linkedin: "https://linkedin.com/",
      twitter: "https://twitter.com/"
    },
    {
      name: "Mme Moustapha Aissata Adamou",
      role: "Commerciale",
      image: "images/adamou.jpg",
      linkedin: "https://linkedin.com/",
      twitter: "https://twitter.com/"
    },
    {
      name: "Daké Félix Nadège-Farida",
      role: "Digital braind Manager",
      image: "images/farida.jpg",
      linkedin: "https://linkedin.com/",
      twitter: "https://twitter.com/"
    },
    {
      name: "ABOUBACAR MOUHA Silimane",
      role: "Assistant Administratif et Financier",
      image: "images/silmane.jpg",
      linkedin: "https://linkedin.com/",
      twitter: "https://twitter.com/"
    },
    {
      name: "Mohamed Kalakali Bachir",
      role: "Assistant IT",
      image: "images/bachir.png",
      linkedin: "https://linkedin.com/",
      twitter: "https://twitter.com/"
    },
    {
      name: "Madame Djamilou Bassira",
      role: "Responsable recouvrement",
      image: "images/bassira.png",
      linkedin: "https://linkedin.com/",
      twitter: "https://twitter.com/"
    }
  ];

  return (
    <Layout>
      <PageBanner 
        title="Notre Équipe" 
        subtitle="Des experts passionnés qui donnent vie à vos projets"
      />
      
      <div className="bg-gradient-to-b from-orange-50 to-white">
        {/* Motifs de fond */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-[linear-gradient(#FB923C10_1px,transparent_1px),linear-gradient(to_right,#FB923C10_1px,transparent_1px)] bg-[size:40px_40px]"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {teamMembers.map((member, index) => (
              <TeamMember 
                key={index}
                member={member}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Equipe;