import React from 'react';
import {  Smartphone, Globe, Camera,  PenTool, Code,  Share2, Search, BarChart, Zap, ArrowRight } from 'lucide-react';
import Layout from '../components/Layout';

const PageBanner = () => (
  <div className="relative bg-gradient-to-r from-gray-900 to-gray-800 overflow-hidden">
    <div className="absolute inset-0">
      <div className="absolute inset-0 bg-[linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341),linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341)] bg-[length:60px_60px] bg-[position:0_0,30px_30px] opacity-20"></div>
      <div className="absolute inset-0 bg-gradient-to-r from-orange-500/20 via-purple-500/20 to-blue-500/20 animate-gradient-x"></div>
      {/* Éléments décoratifs modernes */}
      <div className="hidden md:block absolute top-1/4 right-1/4 w-64 h-64 bg-gradient-to-br from-orange-500/20 to-purple-500/20 rounded-full blur-3xl"></div>
      <div className="hidden md:block absolute bottom-1/4 left-1/4 w-64 h-64 bg-gradient-to-tr from-blue-500/20 to-orange-500/20 rounded-full blur-3xl"></div>
    </div>

    <div className="relative max-w-7xl mx-auto px-4 py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white mb-6">
          Web & Média:<br />Net Agence
        </h1>
        <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
          Créez votre présence digitale avec une agence web créative et innovante
        </p>
        <div className="flex justify-center space-x-4">
          <button className="px-8 py-3 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition-all shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 flex items-center space-x-2">
            <span>Démarrer votre projet</span>
            <ArrowRight className="w-5 h-5" />
          </button>
          <button className="px-8 py-3 bg-white/10 text-white rounded-lg font-semibold hover:bg-white/20 transition-all backdrop-blur-sm">
            Voir nos réalisations
          </button>
        </div>
      </div>
    </div>
  </div>
);

const ServiceCard = ({ icon: Icon, title, description, features }) => (
  <div className="bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border border-orange-100 overflow-hidden group">
    <div className="p-8">
      <div className="w-14 h-14 rounded-xl bg-orange-50 flex items-center justify-center mb-6 group-hover:bg-orange-500 transition-colors">
        <Icon className="w-7 h-7 text-orange-500 group-hover:text-white transition-colors" />
      </div>
      <h3 className="text-xl font-bold text-gray-900 mb-4">{title}</h3>
      <p className="text-gray-600 mb-6">{description}</p>
      <ul className="space-y-3">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start space-x-3">
            <div className="w-5 h-5 rounded-full bg-orange-100 flex items-center justify-center flex-shrink-0 mt-1">
              <div className="w-2 h-2 rounded-full bg-orange-500"></div>
            </div>
            <span className="text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const PortfolioCard = ({ image, title, category, link }) => (
  <div className="group relative overflow-hidden rounded-2xl shadow-lg">
    <img 
      src={image} 
      alt={title} 
      className="w-full h-64 object-cover transform group-hover:scale-110 transition-transform duration-500"
    />
    <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
      <div className="absolute bottom-0 left-0 right-0 p-6">
        <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
        <p className="text-orange-300 mb-4">{category}</p>
        <a 
          href={link}
          className="inline-flex items-center text-white hover:text-orange-300 transition-colors"
        >
          <span>Voir le projet</span>
          <ArrowRight className="w-4 h-4 ml-2" />
        </a>
      </div>
    </div>
  </div>
);

const ProcessStep = ({ number, title, description, icon: Icon }) => (
  <div className="relative">
    <div className="bg-white rounded-2xl shadow-lg p-6 border border-orange-100">
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0 w-12 h-12 bg-orange-500 rounded-lg flex items-center justify-center text-white font-bold">
          {number}
        </div>
        <div>
          <div className="flex items-center space-x-2 mb-2">
            <Icon className="w-5 h-5 text-orange-500" />
            <h3 className="text-lg font-bold text-gray-900">{title}</h3>
          </div>
          <p className="text-gray-600">{description}</p>
        </div>
      </div>
    </div>
  </div>
);

const WebMedia = () => {
  const services = [
    {
      icon: Globe,
      title: "Création de Sites Web",
      description: "Des sites web modernes, performants et adaptés à vos besoins",
      features: [
        "Sites vitrine & e-commerce",
        "Design responsive & moderne",
        "Optimisation SEO",
        "Expérience utilisateur optimale"
      ]
    },
    {
      icon: PenTool,
      title: "Design & Identité Visuelle",
      description: "Création d'identités visuelles uniques et mémorables",
      features: [
        "Logo & charte graphique",
        "Supports de communication",
        "UI/UX Design",
        "Design d'applications"
      ]
    },
    {
      icon: Share2,
      title: "Marketing Digital",
      description: "Stratégies digitales pour augmenter votre visibilité",
      features: [
        "Stratégie social media",
        "Campagnes publicitaires",
        "Content marketing",
        "Email marketing"
      ]
    },
    {
      icon: Camera,
      title: "Production Multimédia",
      description: "Contenu multimédia professionnel pour tous vos besoins",
      features: [
        "Photos professionnelles",
        "Vidéos promotionnelles",
        "Motion design",
        "Podcasts & audio"
      ]
    },
    {
      icon: Smartphone,
      title: "Applications Mobiles",
      description: "Développement d'applications mobiles performantes",
      features: [
        "iOS & Android",
        "Applications natives",
        "Applications hybrides",
        "PWA"
      ]
    },
    {
      icon: BarChart,
      title: "Analytics & SEO",
      description: "Optimisation de votre présence digitale",
      features: [
        "Audit SEO",
        "Analyse de données",
        "Optimisation conversion",
        "Rapports personnalisés"
      ]
    }
  ];

  const portfolio = [
    {
      image: "/api/placeholder/600/400",
      title: "E-commerce Mode",
      category: "Site Web E-commerce",
      link: "#"
    },
    {
      image: "/api/placeholder/600/400",
      title: "Application Mobile Santé",
      category: "Application Mobile",
      link: "#"
    },
    {
      image: "/api/placeholder/600/400",
      title: "Campagne Marketing Digital",
      category: "Marketing",
      link: "#"
    },
    {
      image: "/api/placeholder/600/400",
      title: "Identité Visuelle Restaurant",
      category: "Design",
      link: "#"
    }
  ];

  return (
    <Layout>
      <PageBanner />
      
      {/* Services */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Nos Services</h2>
            <p className="text-gray-600">
              Des solutions digitales complètes pour votre présence en ligne
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>
        </div>
      </section>

      {/* Portfolio */}
      {/* <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Nos Réalisations</h2>
            <p className="text-gray-600">
              Découvrez nos derniers projets et réalisations
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {portfolio.map((project, index) => (
              <PortfolioCard key={index} {...project} />
            ))}
          </div>
        </div>
      </section> */}

      {/* Processus */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Notre Processus</h2>
            <p className="text-gray-600">
              Une approche structurée pour des projets réussis
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <ProcessStep
              number="01"
              icon={Search}
              title="Découverte"
              description="Analyse de vos besoins et objectifs"
            />
            <ProcessStep
              number="02"
              icon={PenTool}
              title="Conception"
              description="Design et planification détaillée"
            />
            <ProcessStep
              number="03"
              icon={Code}
              title="Développement"
              description="Création et intégration"
            />
            <ProcessStep
              number="04"
              icon={Zap}
              title="Déploiement"
              description="Tests et mise en ligne"
            />
          </div>
        </div>
      </section>

      {/* CTA */}
      <section className="py-20 bg-gradient-to-r from-gray-900 to-gray-800 relative overflow-hidden">
        <div className="absolute inset-0 bg-orange-500/10"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Prêt à lancer votre projet digital ?
          </h2>
          <p className="text-lg text-gray-300 mb-8 max-w-2xl mx-auto">
            Contactez-nous pour discuter de votre projet et obtenir un devis personnalisé
          </p>
          <div className="flex justify-center space-x-4">
            <button className="px-8 py-3 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition-all shadow-lg hover:shadow-xl flex items-center space-x-2">
              <span>Démarrer votre projet</span>
              <ArrowRight className="w-5 h-5" />
            </button>
            <button className="px-8 py-3 bg-white/10 text-white rounded-lg font-semibold hover:bg-white/20 transition-all backdrop-blur-sm">
              Voir le portfolio
            </button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default WebMedia;