import React from 'react';
import { Shield, Code, Users, BarChart, Cloud, Monitor,CheckCircle, ArrowRight } from 'lucide-react';
import Layout from '../components/Layout';
import { useNavigate } from 'react-router-dom';


const PageBanner = () => (
  <div className="relative bg-gradient-to-r from-gray-900 to-gray-800 overflow-hidden">
    <div className="absolute inset-0">
      <div className="absolute inset-0 bg-[linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341),linear-gradient(45deg,#162341_25%,transparent_25%,transparent_75%,#162341_75%,#162341)] bg-[length:60px_60px] bg-[position:0_0,30px_30px] opacity-20"></div>
      <div className="absolute inset-0 bg-gradient-to-r from-orange-500/20 via-orange-500/10 to-orange-500/20 animate-gradient-x"></div>
      {/* Cercles décoratifs */}
      <div className="absolute top-0 right-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl transform translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute bottom-0 left-0 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl transform -translate-x-1/2 translate-y-1/2"></div>
    </div>

    <div className="relative max-w-7xl mx-auto px-4 py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white mb-6">
          ICT Consulting
        </h1>
        <p className="text-xl text-orange-200 max-w-3xl mx-auto">
          Des solutions technologiques innovantes pour transformer votre entreprise
        </p>
        <div className="mt-8">
          <button className="px-8 py-3 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition-colors shadow-lg hover:shadow-xl transform hover:-translate-y-0.5">
            Contactez-nous
          </button>
        </div>
      </div>
    </div>
  </div>
);

const Service = ({ icon: Icon, title, description }) => (
  <div className="bg-white p-8 rounded-2xl shadow-lg border border-orange-100 hover:shadow-xl transition-shadow group">
    <div className="w-14 h-14 rounded-xl bg-orange-50 flex items-center justify-center mb-6 group-hover:bg-orange-500 transition-colors">
      <Icon className="w-7 h-7 text-orange-500 group-hover:text-white transition-colors" />
    </div>
    <h3 className="text-xl font-bold text-gray-900 mb-4">{title}</h3>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </div>
);

const Process = ({ number, title, description }) => (
  <div className="flex items-start space-x-4">
    <div className="flex-shrink-0 w-12 h-12 rounded-full bg-orange-500 flex items-center justify-center text-white font-bold text-lg">
      {number}
    </div>
    <div>
      <h3 className="text-lg font-bold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const Expertise = ({ title, items }) => (
  <div className="bg-white rounded-2xl shadow-lg p-8 border border-orange-100">
    <h3 className="text-2xl font-bold text-gray-900 mb-6">{title}</h3>
    <div className="space-y-4">
      {items.map((item, index) => (
        <div key={index} className="flex items-center space-x-3">
          <CheckCircle className="w-5 h-5 text-orange-500 flex-shrink-0" />
          <span className="text-gray-700">{item}</span>
        </div>
      ))}
    </div>
  </div>
);

const IctConsulting = () => {
    const navigate = useNavigate();
  const services = [
    {
      icon: Shield,
      title: "Audit & Sécurité",
      description: "Évaluation complète de votre infrastructure IT et mise en place de solutions de sécurité robustes."
    },
    {
      icon: Code,
      title: "Développement Sur Mesure",
      description: "Création d'applications et de solutions logicielles adaptées à vos besoins spécifiques."
    },
    {
      icon: Users,
      title: "Formation & Support",
      description: "Accompagnement et formation de vos équipes pour une utilisation optimale des solutions mises en place."
    },
    {
      icon: BarChart,
      title: "Analyse & Optimisation",
      description: "Analyse de vos processus IT et recommandations pour améliorer votre efficacité opérationnelle."
    },
    {
      icon: Cloud,
      title: "Solutions Cloud",
      description: "Migration et gestion de vos infrastructures dans le cloud pour plus de flexibilité et de performance."
    },
    {
      icon: Monitor,
      title: "Infrastructure IT",
      description: "Conception et déploiement d'infrastructures IT modernes et évolutives."
    }
  ];

  const expertises = [
    {
      title: "Technologies",
      items: [
        "Cloud Computing (AWS, Azure, GCP)",
        "DevOps & Automatisation",
        "Cybersécurité",
        "Développement Web & Mobile",
        "Intelligence Artificielle & Big Data",
        "IoT & Solutions Connectées"
      ]
    },
    {
      title: "Secteurs d'activité",
      items: [
        "Services Financiers",
        "Secteur Public",
        "Santé",
        "Commerce & Distribution",
        "Industries",
        "Éducation"
      ]
    }
  ];

  return (
    <Layout>
      <PageBanner />
      
      {/* Services */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Nos Services</h2>
            <p className="text-gray-600">Des solutions complètes pour répondre à tous vos besoins en matière de technologie de l'information</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <Service key={index} {...service} />
            ))}
          </div>
        </div>
      </section>

      {/* Processus */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Notre Approche</h2>
            <p className="text-gray-600">Une méthodologie éprouvée pour garantir le succès de vos projets</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <Process 
              number="1"
              title="Analyse des besoins"
              description="Compréhension approfondie de vos objectifs et challenges pour proposer les solutions les plus adaptées."
            />
            <Process 
              number="2"
              title="Conception de solution"
              description="Élaboration d'une solution sur mesure répondant à vos exigences techniques et budgétaires."
            />
            <Process 
              number="3"
              title="Implémentation"
              description="Mise en place des solutions avec un suivi rigoureux et une communication transparente."
            />
            <Process 
              number="4"
              title="Support & Evolution"
              description="Accompagnement continu pour garantir la performance et l'évolution de vos solutions."
            />
          </div>
        </div>
      </section>

      {/* Expertise */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Notre Expertise</h2>
            <p className="text-gray-600">Une maîtrise approfondie des technologies et des secteurs d'activité</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {expertises.map((expertise, index) => (
              <Expertise key={index} {...expertise} />
            ))}
          </div>
        </div>
      </section>

      {/* CTA */}
      <section className="py-20 bg-gradient-to-r from-gray-900 to-gray-800 relative overflow-hidden">
        <div className="absolute inset-0 bg-orange-500/10"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">Prêt à transformer votre entreprise ?</h2>
          <p className="text-lg text-gray-300 mb-8 max-w-2xl mx-auto">
            Contactez nos experts pour discuter de vos projets et découvrir comment nous pouvons vous aider à atteindre vos objectifs.
          </p>
          <button className="px-8 py-3 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition-colors shadow-lg hover:shadow-xl flex items-center space-x-2 mx-auto" onClick={()=>navigate('/rejoignez-nous')}>
            <span>Démarrer votre projet</span>
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </section>
    </Layout>
  );
};

export default IctConsulting;