import { Facebook, Twitter, Linkedin, Mail, ArrowRight, Phone, MapPin, ExternalLink } from 'lucide-react';
import React from 'react';

export default function Footer() {
  const socialLinks = [
    { 
      icon: <Facebook className="w-5 h-5" />, 
      href: "#", 
      color: "hover:bg-[#1877F2]",
      label: "Facebook"
    },
    { 
      icon: <Twitter className="w-5 h-5" />, 
      href: "#", 
      color: "hover:bg-[#1DA1F2]",
      label: "Twitter"
    },
    { 
      icon: <Linkedin className="w-5 h-5" />, 
      href: "#", 
      color: "hover:bg-[#0A66C2]",
      label: "LinkedIn"
    }
  ];

  const usefulLinks = [
    { 
      name: "Niger Emploi", 
      href: "https://nigeremploi.com",
      description: "Premier site d'emploi au Niger"
    },
    { 
      name: "Niger Hosting", 
      href: "https://www.nigerhosting.net/",
      description: "Solutions d'hébergement web"
    },
    { 
      name: "STATDEV", 
      href: "https://statdev.com/",
      description: "Développement informatique"
    }
  ];

  const contactInfo = [
    { 
      icon: <Phone className="w-5 h-5" />, 
      text: "+227 20 32 01 52",
      href: "tel:+22720320152",
      label: "Appelez-nous"
    },
    { 
      icon: <Mail className="w-5 h-5" />, 
      text: "info@aydees.com",
      href: "mailto:info@aydees.com",
      label: "Envoyez-nous un email"
    },
    { 
      icon: <MapPin className="w-5 h-5" />, 
      text: "Quartier ZAC, Niamey. BP: 157 Niamey",
      href: "https://maps.google.com",
      label: "Localisez-nous"
    }
  ];

  return (
    <footer className="relative pt-20 pb-12 bg-[#111827] text-white overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0">
        <div 
          className="absolute inset-0 opacity-5"
          style={{
            backgroundImage: `
              radial-gradient(circle at 25px 25px, white 2px, transparent 0),
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: '50px 50px, 25px 25px, 25px 25px'
          }}
        />
      </div>

      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-12 lg:gap-8">
          {/* About Section - Spans 2 columns */}
          <div className="lg:col-span-2 space-y-8">
            <div className="space-y-6">
              <img 
                src="images/logo_aydees_new.png" 
                alt="Logo" 
                className="h-12 w-auto"
              />
              <p className="text-gray-400 leading-relaxed text-sm">
                AYDEES est votre partenaire idéal pour l'analyse, l'évaluation de vos besoins
                en informatique ainsi que leur optimisation. Notre expertise vous accompagne
                dans votre transformation digitale.
              </p>
            </div>

            {/* Social Links */}
            <div className="space-y-4">
              <h5 className="text-sm font-medium text-gray-300">Suivez-nous</h5>
              <div className="flex space-x-3">
                {socialLinks.map((social, index) => (
                  <a
                    key={index}
                    href={social.href}
                    aria-label={social.label}
                    className={`
                      p-3 rounded-lg transition-all duration-300 
                      bg-gray-800/50 backdrop-blur-sm
                      ${social.color} hover:scale-110 hover:-translate-y-1
                      group relative
                    `}
                  >
                    {social.icon}
                    <span className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 
                      text-xs bg-gray-800 text-white px-2 py-1 rounded opacity-0 
                      group-hover:opacity-100 transition-opacity whitespace-nowrap">
                      {social.label}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Liens Utiles - Spans 2 columns */}
          <div className="lg:col-span-2">
            <h4 className="text-lg font-semibold mb-6 flex items-center space-x-2 relative">
              <span>Liens Utiles</span>
              <div className="flex-grow h-px bg-gradient-to-r from-orange-500/50 to-transparent ml-4"></div>
            </h4>
            <ul className="space-y-6">
              {usefulLinks.map((link, index) => (
                <li key={index}>
                  <a 
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer" 
                    className="group block p-4 rounded-lg bg-gray-800/30 hover:bg-gray-800/50 transition-all duration-300"
                  >
                    <div className="flex items-center justify-between">
                      <span className="font-medium text-gray-300 group-hover:text-white">
                        {link.name}
                      </span>
                      <ExternalLink className="w-4 h-4 text-gray-400 group-hover:text-orange-500" />
                    </div>
                    <p className="mt-1 text-sm text-gray-400 group-hover:text-gray-300">
                      {link.description}
                    </p>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact & Newsletter - Spans 2 columns */}
          <div className="lg:col-span-2 space-y-8">
            {/* Contact Info */}
         

            {/* Newsletter */}
            <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 p-6 rounded-xl backdrop-blur-sm">
              <h4 className="text-lg font-semibold mb-2">Newsletter</h4>
              <p className="text-gray-400 text-sm mb-4">
                Restez informé de nos dernières actualités et mises à jour.
              </p>
              <form className="space-y-3">
                <div className="relative">
                  <input 
                    type="email" 
                    placeholder="Votre email" 
                    className="w-full px-4 py-3 bg-gray-900/50 rounded-lg text-white 
                      placeholder-gray-500 focus:outline-none focus:ring-2 
                      focus:ring-orange-500 transition-all"
                  />
                </div>
                <button 
                  type="submit"
                  className="w-full bg-orange-500 text-white px-6 py-3 rounded-lg 
                    hover:bg-orange-600 transition-all duration-300 
                    flex items-center justify-center group
                    transform hover:-translate-y-0.5"
                >
                  <span>S'abonner</span>
                  <ArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" />
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-16 pt-8 border-t border-gray-800/50">
          <div className="text-center">
            <p className="text-gray-400 text-sm">
              © {new Date().getFullYear()} AYDEES. Tous droits réservés.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}