import './App.css';
import AcceuilPage from './pages/Accueil';
import Layout from './components/Layout';
import React from 'react';

function App() {
  return (
    <Layout>
      <AcceuilPage />
  </Layout>
  );
}

export default App;
