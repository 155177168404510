import { Star , PieChart , Code,ChevronDown , Mail , Phone} from "lucide-react";
import NewsCard from "../components/NewsCard";
import TeamMember from "../components/TeamMember";
import ImageSlider from "../components/ImageSlider";
import React from 'react';
import ServicesSection from "../components/SectionSercice";
import QualityProcessSection from "../components/Qualitysection";
import ContactSection from "../components/ContactSection";
import TeamSection from "../components/TeamMember";
export default function AcceuilPage() {
    const news = [
      {
        category: "Sport",
        date: "21 décembre 2016",
        title: "Latest Fashion Trends 2019",
        image: "/api/placeholder/1000/667"
      },
      {
        category: "Music",
        date: "21 décembre 2016",
        title: "Coffeeshops Continue To Conquer",
        image: "/api/placeholder/1000/667"
      },
      {
        category: "Life Style",
        date: "21 décembre 2016",
        title: "How The Future Could Resemble",
        image: "/api/placeholder/1000/667"
      }
    ];
  
    const team = [
      {
        image: "/api/placeholder/400/500",
        name: "Sarah Johnson",
        role: "CEO"
      },
      {
        image: "/api/placeholder/400/500",
        name: "Michael Chen",
        role: "CTO"
      },
      {
        image: "/api/placeholder/400/500",
        name: "David Smith",
        role: "Lead Developer"
      },
      {
        image: "/api/placeholder/400/500",
        name: "Emma Williams",
        role: "Project Manager"
      }
    ];
  
    const services = [
      {
        icon: <Star className="text-orange-500" />,
        title: "Conseil & Stratégie",
        items: [
          "Audit et Benchmark",
          "Stratégie digitale",
          "Transformation digitale",
          "Formations"
        ]
      },
      {
        icon: <PieChart className="text-green-500" />,
        title: "Digital Marketing",
        items: [
          "Site E-commerce",
          "Campagnes d'e-mailing",
          "Référencement (SEO, SEA, SMO)",
          "Social media marketing"
        ]
      },
      {
        icon: <Code className="text-purple-500" />,
        title: "Développement Web & Mobile",
        items: [
          "Portail web",
          "Application mobile",
          "Collecte de données",
          "Plateforme de visualisation"
        ]
      }
    ];
  
    return (
      <>
        {/* Hero Section */}
        <ImageSlider />
  
        {/* Services Section */}
        <section className="py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <div key={index} className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
                  <div className={`p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4 ${
                    index === 0 ? 'bg-orange-100' : index === 1 ? 'bg-green-100' : 'bg-purple-100'
                  }`}>
                    {service.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
                  <ul className="space-y-2 text-gray-600">
                    {service.items.map((item, i) => (
                      <li key={i} className="flex items-center">
                        <ChevronDown className={`h-4 w-4 mr-2 ${
                          index === 0 ? 'text-orange-500' : index === 1 ? 'text-green-500' : 'text-purple-500'
                        }`} />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Digital Approach Section */}
        <section className="py-20 bg-gradient-to-br from-white via-blue-50/30 to-orange-50/30 relative overflow-hidden">
          {/* Animated Background */}
          <div className="absolute inset-0 z-0">
            <div className="absolute inset-0" 
              style={{
                backgroundImage: `
                  radial-gradient(circle at 20% 30%, rgba(37, 99, 235, 0.05) 0%, transparent 40%),
                  radial-gradient(circle at 80% 70%, rgba(249, 115, 22, 0.05) 0%, transparent 40%),
                  linear-gradient(#2563eb12 1px, transparent 1px),
                  linear-gradient(to right, #2563eb12 1px, transparent 1px)
                `,
                backgroundSize: '20px 20px'
              }}
            ></div>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
            {/* Header Section */}
            <div className="text-center mb-16">
              <div className="inline-block">
                <p className="text-sm font-semibold text-orange-500 uppercase tracking-wide mb-2">
                  Innovation & Excellence
                </p>
                <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold flex flex-col sm:flex-row items-center justify-center gap-2 mb-4">
                  Notre approche digitale chez 
                  <span className="font-serif italic text-orange-500">Aydees</span>
                </h2>
                <div className="h-1 w-20 bg-gradient-to-r from-orange-500 to-blue-500 mx-auto rounded-full"></div>
              </div>
              <p className="mt-8 text-gray-600 max-w-3xl mx-auto text-lg leading-relaxed">
                Nous utilisons des technologies performantes comme Javascript, Next.js, PHP, Kotlin,
                Python, GraphQL, Firebase, Hasura. Nos process de déploiement et
                d'intégration sont optimisés pour accélérer les développements.
              </p>
            </div>

            {/* Cards Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 relative">
              {/* Méthodologie */}
              <div className="group">
                <div className="bg-white p-8 rounded-2xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 relative overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity"></div>
                  <div className="relative z-10">
                    <div className="w-14 h-14 bg-purple-100 rounded-xl mb-6 flex items-center justify-center group-hover:scale-110 transition-transform">
                      <svg className="w-7 h-7 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                      </svg>
                    </div>
                    <h3 className="text-xl font-bold mb-4 group-hover:text-purple-600 transition-colors">Méthodologie</h3>
                    <p className="text-gray-600 leading-relaxed">
                      Notre approche structurée maximise la transparence des budgets et des délais, 
                      en gardant toujours l'objectif final au cœur de nos préoccupations.
                    </p>
                  </div>
                </div>
              </div>

              {/* Innovation */}
              <div className="group">
                <div className="bg-white p-8 rounded-2xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 relative overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-to-br from-orange-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity"></div>
                  <div className="relative z-10">
                    <div className="w-14 h-14 bg-orange-100 rounded-xl mb-6 flex items-center justify-center group-hover:scale-110 transition-transform">
                      <svg className="w-7 h-7 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                      </svg>
                    </div>
                    <h3 className="text-xl font-bold mb-4 group-hover:text-orange-600 transition-colors">Innovation Constante</h3>
                    <p className="text-gray-600 leading-relaxed">
                      Nous explorons et intégrons continuellement de nouvelles technologies pour rester 
                      à la pointe de l'innovation et offrir les meilleures solutions.
                    </p>
                  </div>
                </div>
              </div>

              {/* Esprit d'équipe */}
              <div className="group">
                <div className="bg-white p-8 rounded-2xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 relative overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-to-br from-green-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity"></div>
                  <div className="relative z-10">
                    <div className="w-14 h-14 bg-green-100 rounded-xl mb-6 flex items-center justify-center group-hover:scale-110 transition-transform">
                      <svg className="w-7 h-7 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                      </svg>
                    </div>
                    <h3 className="text-xl font-bold mb-4 group-hover:text-green-600 transition-colors">Esprit d'Équipe</h3>
                    <p className="text-gray-600 leading-relaxed">
                      L'agilité et l'expertise de notre équipe nous permettent de gérer les projets 
                      avec une complémentarité parfaite et une efficacité maximale.
                    </p>
                  </div>
                </div>
              </div>

              {/* Objectif */}
              <div className="group">
                <div className="bg-white p-8 rounded-2xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 relative overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity"></div>
                  <div className="relative z-10">
                    <div className="w-14 h-14 bg-blue-100 rounded-xl mb-6 flex items-center justify-center group-hover:scale-110 transition-transform">
                      <svg className="w-7 h-7 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                      </svg>
                    </div>
                    <h3 className="text-xl font-bold mb-4 group-hover:text-blue-600 transition-colors">Notre Engagement</h3>
                    <p className="text-gray-600 leading-relaxed">
                      Nous développons des solutions robustes et évolutives qui répondent aux plus 
                      hautes exigences de sécurité et de performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Technologies Stack */}
            <div className="mt-16 text-center">
              <p className="text-sm font-semibold text-gray-500 uppercase tracking-wide mb-8">
                Nos technologies principales
              </p>
              <div className="flex flex-wrap justify-center items-center gap-6 md:gap-12">
                {['JavaScript', 'React', 'Next.js', 'Python', 'PHP', 'Kotlin', 'GraphQL', 'Firebase'].map((tech) => (
                  <div key={tech} className="px-4 py-2 bg-white rounded-full shadow-sm hover:shadow-md transition-shadow">
                    <span className="text-gray-600 font-medium">{tech}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Floating Elements */}
          <div className="absolute inset-0 pointer-events-none">
            {[...Array(6)].map((_, i) => (
              <div
                key={i}
                className="absolute w-24 h-24 rounded-full mix-blend-multiply opacity-70 animate-float"
                style={{
                  background: `radial-gradient(circle at center, ${
                    ['#3b82f6', '#f97316', '#8b5cf6', '#22c55e'][i % 4]
                  }15, transparent)`,
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 100}%`,
                  animationDelay: `${i * 0.5}s`,
                  animationDuration: `${3 + Math.random() * 2}s`
                }}
              />
            ))}
          </div>
        </section>
        <ServicesSection />
        <QualityProcessSection />
        {/* Actualités Section */}
        {/* <section className="py-16 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
              <p className="text-sm font-semibold text-orange-500 uppercase tracking-wide">
                Actualités / Événements
              </p>
              <h2 className="text-3xl font-bold mt-2">
                Voir nos dernières actualités et événements
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {news.map((item, index) => (
                <NewsCard key={index} {...item} />
              ))}
            </div>
          </div>
        </section> */}
        {/* Team Section */}
          <TeamSection />
  
        {/* Contact Form Section */}
          <ContactSection />
      </>
    );
  }
  