import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import RejoignezNous from './pages/RejoignezNous';
import Equipe from './pages/Equipe';
import Bibliographie from './pages/Bibligraphie';
import IctConsulting from './pages/IctConsulting';
import RhFormation from './pages/RhFormation';
import EtudesConseilInfo from './pages/EtudeConseil';
import RessourcesHumaines from './pages/RessourceHuamaines';
import WebMedia from './pages/WebMedia';
import NosPartennaires from './pages/Nospartennaires';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/rejoignez-nous" element={<RejoignezNous />} />
      <Route path="/notre-equipe" element={<Equipe />} />
      <Route path="/bibliographie" element={<Bibliographie />} />
      <Route path="/ict-consulting" element={<IctConsulting />} />
      <Route path="/rh-formation" element={<RhFormation />} />
      <Route path="/etude-conseil" element={<EtudesConseilInfo />} />
      <Route path="/resources-humaines" element={<RessourcesHumaines />} />
      <Route path="/web-media" element={<WebMedia />} />
      <Route path="/nos-partennaires" element={<NosPartennaires />} />
    </Routes>
  </BrowserRouter>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
