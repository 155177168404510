import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function Layout({ children }) {
  return (
    <div className="min-h-screen flex flex-col overflow-x-hidden w-full">
      <Header />
      <main className="flex-grow pt-32 w-full">
        <div className="w-full">
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
}